@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2{
  font-size: 20px;
  font-weight: bold;
  
}
.sticky-column{
  position: sticky;
  left: 0;
  background: #f9f9f9; /* Optional: Background color for the sticky column */
  z-index: 1;
}

.sticky-row{
  position: sticky;
  top: 0;
  background: #f9f9f9; /* Optional: Background color for the sticky column */
  z-index: 2;
}

#salary-table{
  position: sticky;
  overflow-y: auto
}
.salary-sheet-table {
  margin-top:20px;
  td {
    a {
      color: blue;
      text-decoration: underline;
    }

    font-size: 14px;
    padding: 15px;
    border: solid 1px lightgray;
  }

  .amount {
    text-align: right;
  }

  th {
    font-size: 12px;
    padding: 15px;
    border: solid 1px lightgray;
  }
}